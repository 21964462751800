import { MapContainer, TileLayer, LayersControl } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { Container, Form, Button } from "react-bootstrap";
import { useState, useEffect, useMemo } from "react";
import Legend from "./Legend";
import Navbar from "./Navbar";
import LocationMarker from "./LocationMarker";
import axios from "axios";

const position = [20.5937, 78.9629];

const Mapbox = () => {
  const [mapType, setMapType] = useState("cwu");
  const [year, setYear] = useState("2001");
  const [colormap, setColormap] = useState("inferno");
  const [activeRange, setActiveRange] = useState([0, 1]);
  const [success, setSuccess] = useState(false);
  const cwu_year_arr = useMemo(() => [
    "2001",
    "2002",
    "2003",
    "2004",
    "2005",
    "2006",
    "2007",
    "2008",
    "2009",
    "2010",
    "2011",
    "2012",
    "2013",
    "2014",
    "2015",
  ])

  const gpp_year_arr = useMemo(() => [
    "2000",
    "2001",
    "2002",
    "2003",
    "2004",
    "2005",
    "2006",
    "2007",
    "2008",
    "2009",
    "2010",
    "2011",
    "2012",
    "2013",
    "2014",
    "2015",
  ]);

  const colormaps = useMemo(() => [
    { name: "Inferno", value: "inferno" },
    { name: "Viridis", value: "viridis" },
    { name: "Plasma", value: "plasma" },
    { name: "Magma", value: "magma" },
    { name: "Rainbow", value: "rainbow" },
    { name: "Spring", value: "spring" },
    { name: "Jet", value: "jet" },
    { name: "Summer", value: "summer" },
    { name: "Hot", value: "hot" },
    { name: "Greens", value: "greens" },
  ]);

  const handleDownload = () => {
    const url = `https://wrdm-server-bucket.s3.amazonaws.com/${mapType === 'gpp' ? 'gpp_med' : mapType}_${year}.tif`
    window.open(url, '_blank');
    // axios.get(url, {
    //   responseType: 'blob'
    // }).then((response) => { });
  }


  useEffect(() => {
    axios.get(`${process.env.REACT_APP_TILE_SERVER_URI}/metadata/${mapType}/${year}`)
      .then((json) => {
        console.log(json.data);
        setSuccess(true)
        setActiveRange([json.data.percentiles[4], json.data.percentiles[96]]);
      });
  }, [mapType, year]);

  return (
    <div>
      <Navbar active={'Home'} />
      <Container>
        <Form className="d-flex">
          <div className="d-flex flex-grow-1 align-items-end">
            <Form.Group className="my-3">
              <div className="flex-grow-1 me-3">
                <Form.Label className="mb-0" style={{ fontWeight: 700 }}>
                  Select Map Type
                </Form.Label>
                <Form.Select
                  value={mapType}
                  onChange={(ev) => setMapType(ev.target.value)}
                >
                  <option value={"cwu"}>Crop Water Usage</option>
                  <option value={"gpp"}>Crop Yield Data</option>
                </Form.Select>
              </div>
            </Form.Group>

            <Form.Group className="m-3">
              <div className="flex-grow-1 me-3">
                <Form.Label className="mb-0" style={{ fontWeight: 700 }}>Select Year</Form.Label>
                {mapType === "cwu" ? (
                  <Form.Select
                    value={year}
                    onChange={(ev) => setYear(ev.target.value)}
                    style={{ width: "120px" }}
                  >
                    {cwu_year_arr.map((yr, ind) => (
                      <option key={ind} value={yr}>
                        {yr}
                      </option>
                    ))}
                  </Form.Select>
                ) : (
                  <Form.Select
                    value={year}
                    onChange={(ev) => setYear(ev.target.value)}
                  >
                    {gpp_year_arr.map((yr, ind) => (
                      <option key={ind} value={yr}>
                        {yr}
                      </option>
                    ))}
                  </Form.Select>
                )}
              </div>
            </Form.Group>
            <Form.Group className="m-3">
              <div className="flex-grow-1 me-3">
                <Form.Label className="mb-0" style={{ fontWeight: 700 }}>
                  Select Colormap
                </Form.Label>
                <Form.Select
                  value={colormap}
                  onChange={(ev) => setColormap(ev.target.value)}
                >
                  {colormaps.map((col, ind) => (
                    <option key={ind} value={col.value}>
                      {col.name}
                    </option>
                  ))}
                </Form.Select>
              </div>
            </Form.Group>
          </div>
          <div className="d-flex my-3 align-items-end">
            <Button className="d-flex align-items-center" onClick={handleDownload}>
              <i className="fa fa-download" aria-hidden></i>
              <div style={{ marginLeft: '6px', fontWeight: '600', fontSize: '15px' }}>
                Download
              </div>
            </Button>
          </div>
        </Form>
        <div
          id="map"
          className="rounded p-2 border border-secondary shadow-5"
          style={{ height: "80vh", width: "100%" }}
        >
          <MapContainer center={position} zoom={5} style={{ height: "100%" }}>
            <LayersControl position="bottomleft">
              <LayersControl.BaseLayer checked name="OpenStreetMap">
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
              </LayersControl.BaseLayer>

              <LayersControl.BaseLayer name="Topo View">
                <TileLayer
                  url="https://c.tile.opentopomap.org/{z}/{x}/{y}.png"
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
              </LayersControl.BaseLayer>

              <LayersControl.BaseLayer name="Image View">
                <TileLayer
                  url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png"
                  maxZoom={20}
                />
              </LayersControl.BaseLayer>

              <LayersControl.BaseLayer name="Grey View">
                <TileLayer
                  url="https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}.png"
                  maxZoom={20}
                />
              </LayersControl.BaseLayer>

              <LayersControl.BaseLayer name="Watercolor">
                <TileLayer
                  url="http://a.tile.stamen.com/watercolor/{z}/{x}/{y}.png"
                  maxZoom={20}
                />
              </LayersControl.BaseLayer>
              {success ?
                <LayersControl.Overlay checked name="Data Layer">
                  <TileLayer
                    opacity={0.9}
                    url={`${process.env.REACT_APP_TILE_SERVER_URI}/singleband/${mapType}/${year}/{z}/{x}/{y}.png?colormap=${colormap}&stretch_range=${JSON.stringify(
                      activeRange
                    )}`}
                  />
                </LayersControl.Overlay>
                : null}
            </LayersControl>
            {/* <FullscreenControl position="top-left" /> */}
            <LocationMarker mapType={mapType} year={year} />
            <Legend color={colormap} range={activeRange} mapType={mapType} />
          </MapContainer>
        </div>
      </Container>
    </div>
  );
};

export default Mapbox;
