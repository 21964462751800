import React, { useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";


const Navbar = ({ active }) => {
  const navigate = useNavigate();
  const links = useMemo(() => [
    {
      link: '/',
      name: 'Home',
    },
    {
      link: '/team',
      name: 'Team',
    }
  ])

  return (
    <nav
      className="navbar navbar-expand-lg navbar-dark px-0 py-3 sticky-top"
      style={{
        backgroundColor: "#022d45",
        height: "65px",
        width: "100%",
      }}
    >
      <div className="container-xl">
        <Link className="navbar-brand" to="/">
          <h2 className="text-white logoFont">HydroMetLab</h2>
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav mx-lg-auto">
            {links.map((link, indx) => (
              <Link key={indx} className={`nav-item nav-link ${link.name === active ? 'active' : ''}`} to={link.link}>
                {link.name}
              </Link>
            ))
            }
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
