import axios from "axios";
import { useState } from "react";
import { Card, Table } from "react-bootstrap";
import { Marker, Popup, useMapEvents } from "react-leaflet";
import L from "leaflet";

const LocationMarker = ({ mapType, year }) => {
  const [val, setVal] = useState(null);
  const [error, setError] = useState(false);
  const icon = L.icon({
    iconUrl: `${window.location.origin}/marker-icon.png`,
    className: "leaflet-dev-icon",
    iconSize: [25, 41],
    iconAnchor: [12.5, 41],
  });

  useMapEvents({
    click(event) {
      setError(false);
      const { lat, lng } = event.latlng;
      axios
        .post(
          `${process.env.REACT_APP_API_SERVER_URI}/fetchval/${mapType}/${year}`,
          { lat, long: lng }
        )
        .then((json) => {
          let dt = json.data;
          if (typeof json.data === "string") {
            dt = {
              lat: lat,
              long: lng,
              val: "No Data",
            };
          } else {
            dt = json.data;
          }
          console.log(dt);
          if (isNaN(dt.val)) {
            setVal({
              lat: dt.lat,
              long: dt.long,
              val: "No Data",
            });
          } else {
            setVal({
              lat: dt.lat,
              long: dt.long,
              val: dt.val.toFixed(3),
            });
          }
        });
    },
  });

  if (val) {
    return (
      <div className="leaflet-top leaflet-right">
        <div
          className="leaflet-control leaflet-bar mt-2 p-1"
          style={{ backgroundColor: "white", paddingRight: "5px" }}
        >
          <Card
            style={{
              width: "200px",
              height: "150px",
              padding: "10px",
              zIndex: 1000,
              opacity: 0.9,
              fontWeight: 600,
              fontSize: "15px",
              flexDirection: "inherit",
            }}
          >
            <Table>
              <tbody>
                <tr>
                  <td className="head-col">Latitute</td>
                  <td className="val-col">{val.lat?.toFixed(3)}</td>
                </tr>
                <tr>
                  <td className="head-col">Longitude</td>
                  <td className="val-col">{val.long?.toFixed(3)}</td>
                </tr>
                <tr>
                  <td className="head-col">Value</td>
                  <td className="val-col">{val.val}</td>
                </tr>
              </tbody>
            </Table>
          </Card>
          <Marker position={[val.lat, val.long]} icon={icon}>
            {/* <Popup> */}
            {/* <span>{val.val ? val.val.toFixed(3) : 'No Data'}</span> */}
            {/* </Popup> */}
          </Marker>
        </div>
      </div>
    );
  } else return null;
};

export default LocationMarker;
